import { apiRoutes, buildApiRoute } from "../config/apiRoutes";
import { useEngagementTeamConfirmationsApi } from "./useEngagementTeamConfirmationsApi";

export function useTeamMembers() {
  const { apiGet, apiPost, apiRemove } = useEngagementTeamConfirmationsApi();

  async function GetTeamMembers(engagementYearId) {
    return await apiGet(buildApiRoute(apiRoutes.TeamMembers.getAll, { eyid: engagementYearId}));
  }
  
  async function GetUsers(value) {
    console.log(value)
    return await apiGet(buildApiRoute(apiRoutes.TeamMembers.getUsers, { startsWith: value}));
  }
  
  async function AddTeamMember(engagementYearId, createdByEmail, users) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.Add), { "EngagementYearId":engagementYearId, "CreatedByEmail":createdByEmail,
    "Users": users
  });
  }

  async function SelfAddTeamMember(engagementYearId) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.SelfAdd, { engagementYearId }));
  }

  async function InviteExternalUser(email, enName, request) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.inviteExternal), { "recipient":email, "engagementName":enName, "requestor":request 
  });
  }

  async function GetRoles() {
    return await apiGet(buildApiRoute(apiRoutes.TeamMembers.getRoles));
  }

  async function SendBulkInvite(eyId, createdByEmail) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.bulkInvite), { "EngagementYearId":eyId, "CreatedByEmail":createdByEmail 
  });
  }

  async function SendRequest(eyId, createdByEmail, teamMemberId) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.sendRequest), { "EngagementYearId":eyId, "EngagementYearTeamMemberId":teamMemberId,
    "CreatedByEmail":createdByEmail 
  });
  }

  async function GetRole(eyId, userEmail) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.getRoleForUser), { "EngagementYearId":eyId, "Email":userEmail
  });
  }

  async function ResendRequest(eyId, confirmationTypeId, eyTeamMemberId, resendNote, createdByEmail) {
    return await apiPost(buildApiRoute(apiRoutes.TeamMembers.resendRequest), { "EngagementYearId":eyId, "ConfirmationTypeId":confirmationTypeId,
    "EngagementYearTeamMemberId": eyTeamMemberId, "ResendNote":resendNote, "CreatedByEmail":createdByEmail
  });
  }

  async function RemoveTeamMember(eyId, eyTeamMemberId) {
    console.log(eyTeamMemberId);
    return await apiRemove(buildApiRoute(apiRoutes.TeamMembers.removeTeamMember, { teamMemberId: eyTeamMemberId}), { "EngagementYearId": eyId, "EngagementYearTeamMemberId": eyTeamMemberId
    });
  }

  return { GetTeamMembers, GetUsers, AddTeamMember, GetRoles, InviteExternalUser, SendBulkInvite, SendRequest, ResendRequest, GetRole, RemoveTeamMember, SelfAddTeamMember };
}
